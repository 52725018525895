.trackContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.trackContainer p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: #031569;
  margin-left: 8px;
}

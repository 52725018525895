.d-flex {
  display: flex;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.d-flex-center {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 5px;
}

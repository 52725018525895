/* .dashboardCard {
  width: 200px;
  height: auto;
  background-color: #031569;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
  color: aliceblue;
}

.dashboardCard h2 {
  font-size: 24px;
  color: aliceblue;
  font-weight: 600;
}

.dashboardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.mapContainer {
  margin-bottom: 20px;
} */

.dashBoardContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dashBoardContainer .heading {
  color: #000;
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px 20px 0px;
}

.cardRow {
  display: grid;
  grid-template-columns: 30% 30% 30%;
}

.cardRow2 {
  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
}

.dashcard {
  display: flex;
  height: 120px;
  padding: 14px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  background: var(--surface-surface-default, #fff);
  /* Shadow Base */
  box-shadow: 0px 0px 0px 1px rgba(63, 63, 68, 0.05),
    0px 1px 3px 0px rgba(63, 63, 68, 0.15);
  margin-right: 15px;
}

.cardTitle {
  color: var(--neutral-4, #606b6c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.cardStats {
  color: var(--neutral-5, #121616);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 116.667% */
}

.loginContainer {
  display: flex;
  padding: 40px;
  height: 100vh;
  position: relative;
  background: #f7f9ff;
}

.image {
  width: 50%;
}

.image img {
  height: 100%;
  width: 100%;
}

.loginBox {
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carID {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.carID label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.carID input {
  margin-top: 10px;
  width: 450px;
  height: 56px;
  border: transparent;
  background: #f3f2f3;
  border-radius: 8px;
  padding-left: 20px;
}

.text h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #000000;
}

.text p {
  margin: 20px 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #282828;
}

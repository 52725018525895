.listedContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cross {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.accordion {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

.accordion h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.03em;

  /* Blue */

  color: #031569;
  margin-left: 20px;
}

.confirmDeleteModal {
  width: 100%;
  height: 311px;
  /* margin: 0 auto; */
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalHeading {
  width: 80%;
  line-height: 25px;
}

.viewDoc {
  padding: 20px 50px;
  box-shadow: 90px 90px 90px 90px rgba(0, 0, 0, 0.04);
  width: 50%;
  margin-top: 80px;
  background-color: #ffffff;
  max-height: 1100px;
  min-height: 280px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.viewDoc img {
  /* max-height: 400px; */
}

.modal {
  box-shadow: 90px 90px 90px 90px rgba(0, 0, 0, 0.04);
  width: 50%;
  background-color: #ffffff;
  max-height: auto;
  min-height: 280px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 30px;
}

.modal h1 {
  margin-top: 20px;
  font-weight: 600;
  font-size: 29px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 20px;
  width: 80%;
  line-height: 35px;
}

.modal img {
  height: 32px;
  width: 32px;
}

.confirmDeleteModal a .addCategory {
  width: 100%;
}

.addCategoryForm {
  width: 100%;
  display: flex;
  margin: 0px 0px 20px 0px;
}

.addCategoryBody {
  border-bottom: #f3f2f3 solid 1px;
  background: #ebeffe;
  padding: 20px 20px 10px 20px;
}

.listedVehicles {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10%;
  width: 836px;
}

.carImageContainer {
  box-sizing: border-box;
  width: 100%;
  height: 301px;
  background: #ebeffe;
  border: 0.8px dashed #031569;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carID {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.carID label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.carID input {
  margin-top: 10px;
  height: 56px;
  border: transparent;
  background: #f3f2f3;
  border-radius: 8px;
  padding-left: 20px;
}

.flexRow {
  display: flex;
  width: 100%;
  gap: 2.5%;
  align-items: center;
}

.actionButtons {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  gap: 3%;
}

.tableContainer {
  margin-top: 30px;
}

.actionButtons2 {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  gap: 5%;
}
.modalContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 50px;
  /* height: auto; */
}

.createSubscription {
  width: 951px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.heading h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #031569;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.actionButtons {
  width: 100%;
  align-self: flex-end;
}

/* .QNR .QNRimage {
  height: 550px;
  width: 350px;
} */

.viewQNR {
  padding: 10px 10px;
  margin-top: 100px;
  display: flex;
  align-items: center;
}

.qnrNumber {
  margin-bottom: 20px;
}

.QNR {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid #031569;
  width: 400px;
  border-radius: 30px;
  min-height: 550px;
  max-height: 100%;
  margin-bottom: 40px;
  width: 70%;
  margin: 0 auto;
}

.QNR h1 {
  font-size: 20px;
  font-weight: 600;
}

.dragAndDrop {
  width: 409px;
  height: 300px;
  background: #ebeffe;
  border: 0.8px dashed #031569;

  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carImageAndQNR {
  display: flex;
  gap: 2%;
}

.otherCarDetails {
  display: flex;
  width: 836px;
  border: 0.8px solid #e6e8f0;
  border-radius: 12px;
  gap: 5%;
  padding: 20px;
  margin: 20px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.column {
  width: 50%;
}

.column1 {
  width: 100%;
}

.detail {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.dragAndDrop img {
  max-width: 278px;
  max-height: 250px;
  border-radius: 10px;
  margin: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.dragAndDrop h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #343434;
  margin: 10px;
}

.dragAndDrop p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ababab;
}

.reSelect {
  align-self: flex-end;
  margin-right: 40px;
  color: #031569 !important;
  text-decoration: underline;
}

.logo {
  width: 149px;
  height: 34px;
}
.notification {
  display: flex;
  width: 27px;
  height: 22px;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: var(--Placeholder, #f3f2f3);
}

.notification p {
  color: va #031569 !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cuImage {
  margin: 20px;
  width: 220px;
  height: 220px;
  border-radius: 50%;
}

.marker {
  height: 20px;
  width: 20px;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.containerSubRight {
  width: 100%;
  padding: 0px 10px 20px 0px;
}

.subRight-boundary {
  background: #ffffff;
  border-radius: 8px;
  padding: 15px 15px 20px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.carImage {
  width: 350px;
  height: 220px;
}

.suspensionTextarea {
  border: #a3a3a3 solid 1px;
  width: 80%;
  padding: 10px;
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.border-bottom2 {
  border-bottom: 0.6px solid #e7e7e7;
  padding: 0px 0px 10px 5px;
}

.back-button img {
  margin-right: 10px;
}

.containerSubRight .heading-container h1,
.heading-container-chev h1 {
  font-weight: 700;
  font-size: 24px;
}

.suspend {
  height: 25px;
  width: 25px;
  margin-bottom: -4px;
}

.actionsCategories button:nth-child(1) {
  margin-right: 10px;
}

.heading-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search {
  width: 477px;
  height: 55px;
  background: #fafafa;
  border-radius: 80px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  margin-left: 50px;
  gap: 2%;
}

.search input {
  border: none;
  width: 100%;
  background: #fafafa;
}

.search input:focus {
  outline: none;
}

.search img {
  width: 16px;
  height: 16px;
}

.btn-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 138px;
  width: 450px;
  height: 56px;
  background: #031569;
  border-radius: 80px;
  color: white;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 40px;
  letter-spacing: 0.03em;
  color: #ffffff;
}

.red {
  margin: 10px 5px;
  color: red;
}

.active .notification {
  color: #031569;
}

.active {
  background-color: #031569;
  color: white !important;
  font-weight: 500;
}

.active2 {
  margin-left: -20px;
  background: red !important;
  color: red;
}

.active img {
  -webkit-filter: invert(1) contrast(500%);
}

.profileOptions {
  display: flex;
  align-items: center;
  gap: 10%;
  justify-self: right;
  margin: 10px;
}

.profileOptions h2 {
  background: #f5f5f5;
  border-radius: 17.3325px;
  width: 104px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.tab {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 5px 0px;
}

.onGoingRide {
  background-color: #008000;
  color: #f5f5f5;
}

.inprogress {
  background-color: #ffa500;
  color: #f5f5f5;
}

.imageSuggest {
  font-size: 14px;
}

.completedRide {
  background-color: #031569;
  color: #f5f5f5;
}

.red2 {
  background-color: #ff0000 !important;
  font-weight: 600;
}

.cancelledRide {
  background-color: #ff0000;
  color: #f5f5f5;
}

.profileOptions .adminImage {
  width: 35px;
  height: 34px;
  border-radius: 25px;
  z-index: 10;
}

.tableHeader {
  background-color: #031569 !important;
  color: white;
  height: 60px;
  border: none;
}

.headerID {
  max-width: 50%;
}

.id {
  max-width: 50%;
}

table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.table-row:hover {
  cursor: pointer;
  border-top: #031569 1px solid;
  border-bottom: #031569 1px solid;
  transition: 0.5s;
}

table th {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #ffffff;
}

.markerStarting {
  display: none;
}
.selectedTab {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 17px;
  color: #031569;
  padding: 4px;
}

.qnrDetails p {
  margin: 20px;
  font-weight: 600;
}

.normalTab {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Inactive */
  padding: 4px;

  color: #a3a3a3;
}

table td,
table th {
  /* border: 1px solid #ddd; */
  padding: 8px;
  height: 60px;
  text-align: center;
  /* max-width: 110px; */
}

.nameBox {
  /* min-width: fit-content; */
}

.imgCont {
  position: relative;
}

/* .banner {
imag
} */

.copyRight {
  align-self: flex-end;
  position: absolute;
  bottom: 50px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;

  color: #7b7b7b;
}

.topRight {
  position: absolute;
  top: 0px;
  right: 10px;
  width: 54px !important;
  height: 283px !important;
}

.bottomLeft {
  position: absolute;
  bottom: 0px;
  left: 10px;
  width: 106px !important;
  height: 168px !important;
}

.address {
  min-width: 100px;
  max-width: 200px;
  margin: 0 auto;
  /* align-self: center; */
}

/* td {
  margin: 0 auto;
} */

table tbody {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: justify;
  letter-spacing: 0.04em;
  color: #000000;
}

table tbody tr:nth-child(odd) {
  background: #ebeffe;
}

.back-blue {
  background-color: #031569;
}

.smallGrey {
  color: #858585 !important;
  font-size: 14px;
}

.smallBlack {
  color: black !important;
  font-size: 14px;
}

.color-black {
  color: black;
  font-weight: 500;
}

.height {
  height: min-content !important;
}

.carPriceDetails {
  display: flex;
  gap: 20px;
}

.detailRow {
  gap: 20px;
}

.changeCarPrice {
  display: flex;
}

.labelCar {
  font-size: 17px;
  font-weight: 600;
}

.addButton {
  padding: 10px;
  background: #031569;
  color: white;
  font-weight: 500;
  border-radius: 10px;
  margin-top: 30px;
}

.btn-edit,
.btn-edit2 {
  background: none;
  border: none;
  transition: all 300ms cubic-bezier(0.1, 1, 0.32, 1);
  touch-action: manipulation;
}

.btn-edit3 {
  background: #031569;
  border: none;
  transition: all 300ms cubic-bezier(0.1, 1, 0.32, 1);
  touch-action: manipulation;
  border-radius: 10px;
  color: white;
  padding: 8px;
}

/* CSS */

.button-27:disabled {
  pointer-events: none;
}

.btn-edit:hover {
  /* box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px; */
  transform: translateY(-2px);
}

.loader {
  width: 18px;
  height: 18px;
  border: 2px solid red;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-edit2 {
  margin-top: 10px;
}

.border-bottom {
  padding-bottom: 20px;
  border-bottom: #a3a3a3 0.6px solid;
}
.center {
  text-align: center;
}

/* .btn-edit img {
  width: 20px;
  height: 20px;
}  */

.btn-edit2 img {
  width: 28px;
  height: 26px;
}

.actionButtons {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 2%;
}

.loaderDivContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12%;
}

.loader-container1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Translucent black background */
}

.loader1 {
  border: 8px solid #f3f3f3; /* Light gray */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-approve {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15.0696px 103.98px;
  gap: 7.53px;
  width: 174.81px;
  height: 42.19px;
  color: white;
  background: #031569;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.17);
  border-radius: 60.2783px;
}

.btn-approve:hover {
  background-position: 100% 0;
  /* moz-transition: all 0.4s ease-in-out; */
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-reject {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15.0696px 103.98px;
  gap: 7.53px;

  width: 174.81px;
  height: 42.19px;
  color: #ff0000;
  border: 2px solid #ff0000;
  border-radius: 60.2783px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chevAndTitle {
  display: flex;
}

.chevAndTitle img {
  margin-right: 10px;
}

.chev {
  height: 25px;
  margin-top: 7px;
}

.btn-reset {
  width: 189.95px;
  height: 52.85px;
  border: 2px solid #a3a3a3;
  border-radius: 80px;
}

.address2 {
  width: 170px;
}

.date {
  width: 110px;
}

.btn-suspend {
  gap: 10px;
  width: 174.81px;
  height: 42.19px;
  background: #ff0000;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.17);
  border-radius: 80px;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  margin-left: 20px;
}

.margint-20 {
  margin-top: 30px;
}

.red-cross {
  margin-bottom: -3px;
}

.listed-buttons {
  display: flex;
}

.categories {
  height: 20px;
}

.suspend11 {
  width: 30px;
  height: 20px;
}

.btn-revoke {
  gap: 10px;
  width: 174.81px;
  height: 52.19px;
  background: #031569;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.17);
  border-radius: 80px;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  font-weight: 500;
}

.long {
  width: 300px;
}

.margin-top {
  margin-top: 30px;
}

.categoryInput {
  width: 75%;
}

.btn-addVehicle {
  gap: 10px;
  width: 174.81px;
  height: 42.19px;
  background: #031569;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.17);
  border-radius: 80px;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}

.backAndHeading {
  display: flex;
}

.blueText {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-top: 20px;

  color: #031569;
}

.grayText {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: #858585;
}

.blackText {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #1e1e1e;
}

.suspensionNotice {
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  /* width: 745px; */
  background: #ffe3e3;
  border-radius: 11px;
  color: #ff0000;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  align-items: center;
  justify-content: center;
}

.searchContainerQues {
  width: 343px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  background: #ffffff;
  /* border: 1px solid #a3a3a3; */
  padding-right: 20px;
}

.searchContainerQues input {
  width: 100%;
  border: transparent;
  padding-left: 20px;
  font-size: 20px;
  background: transparent;
  border-radius: 10px;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* color: #1f1f1f; */
}

.searchContainer input:focus {
  width: 800%;
  transition: width 250ms ease-in-out;
}

.searchContainerQues input::selection {
  border: none;
}

.searchContainer .magnify > img,
.searchContainerQues .magnify > img {
  width: 25px;
  padding-left: 20px;
}

.searchContainerQues .magnify > img {
  width: 35px;
  padding-left: 12px;
}

.searchContainer input:focus,
.searchContainerQues input:focus {
  outline: none;
}

.green {
  color: #008000;
}

.about {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #a3a3a3;
}

.tableButtons {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}

.tableNav {
  border: none;
  background: transparent;
  margin: 0px 10px;
}

.pageNumber {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #031569;
}

.loader-container2 {
  margin-top: 100px;
  margin-left: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.loader-container {
  margin-top: 250px;
  margin-left: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.loader-container img {
  border-radius: 50%;
}

.refreshLoader {
  margin: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.dashboardView {
  height: 100%;

  margin-top: 63px;
  background-color: #f5f5f5;
}

.nameAndImage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* justify-content: center; */
  gap: 5%;
  margin-right: 30px;
}

.availContainer {
  width: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: center; */
}

.docAvail {
  width: 30px;
  height: 30px;
}

.UserDoc {
  max-height: 500px;
  border-radius: 30px;
}

.nameAndImage img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.emptyState {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #031569;
  margin: 20px 0px;
}

.modalText {
  width: 58%;
  font-size: 27px !important;
}

.emptyData {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
  font-size: 25px;
  font-weight: 600;
}

.blue {
  color: #031569;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.viewIcon {
  height: 15px;
  width: 15px;
}

.parkingListing {
  border-radius: 10px;
  border: #7b7b7b 2px solid;
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 10px;
  width: fit-content;
  gap: 10px;
}

.coordsAndButton {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #000000;
}

.coordsAndButton button {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  font-weight: 600;
}

/* ShinyLoader.css */
.shiny-loader {
  width: 50px;
  height: 50px;
  position: relative;
  margin: 0 auto;
  transform: translate(0, 50%);
}

.text-red {
  color: red !important;
  font-weight: 600 !important;
}
.shiny-loader-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(45deg, transparent 50%, red 50%, blue 100%);
  background-size: 200% 100%;
  animation: shine 1s linear infinite;
}

@keyframes shine {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

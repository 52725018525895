.onGoingRideContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.onGoingRideContainer .imagesInsp {
  margin-top: 20px;
  width: 130px;
  /* width: 100%; */
}

.mapContainer {
  width: 400px;
  height: 400px;
}

.tripDetails {
  margin-top: 20px;
}

.otherInfo {
  display: flex;
  gap: 4%;
  margin-top: 20px;
  width: 97%;
}

.cuInfo {
  width: 50%;
}

.cuInfo h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: 0.02em;

  color: #000000;
}

.infoRow {
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
}

.infoRow {
}

.infoRowBlueBackground {
  display: flex;
  justify-content: space-between;
  background: #ebeffe;
  height: 35px;
  align-items: center;
  padding: 5px;
}

.infoRow p:nth-child(1) {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #858585;
  width: 50%;
}

.infoRow p:nth-child(2) {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #1e1e1e;
  width: 50%;
}

.transactions {
  margin-top: 20px;
}

.blue {
  /* Blue */

  color: #031569 !important;
}

.uploadedVideo {
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  margin-top: 20px;
}

.afterImages {
  margin-top: 20px;
}

.uploadedHeading {
  margin-top: 20px;
}

.uploadedVideo p {
  font-size: 16px;
}

.images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;
}

.images img {
  width: 210px;
  height: 115px;
  border-radius: 10px;
}

.images p {
  margin-top: 20px;
  font-size: 15px;
}

/* .uploadedVideo img {
  height: 18px;
  width: 16px;
  margin-bottom: 12px;
  margin-right: 15px;
} */

.dark {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #000000;
}

.faded {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.03em;

  /* Inactive */
  margin-top: 5px;
  color: #a3a3a3;
}

.totalFareBox {
  margin-top: 20px;
  width: 100%;
  border: 1px solid #031569;
}

.green {
  color: #008000 !important;
}

.greenLarge {
  font-size: 20px !important;
  color: #008000 !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}

.totalFareBox .infoRow {
  padding: 5px;
}

.totalFareBox .infoRow p:nth-child(1) {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;

  color: #343434;
}

.navContainer {
  position: relative;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  align-items: center;
  padding-left: 20px;
  justify-content: space-between;
  padding: 0px 20px;
  width: 98%;
  background-color: #ffffff;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.04);
  width: 100%;
  z-index: 2;
}

.logoAndSearch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 45%;
}

@media (max-width: 1280px) {
  .logoAndSearch {
    width: 58%;
  }
}

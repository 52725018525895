.sidebar {
  width: 212px;
}

.navBar {
  position: fixed;
  top: 0%;
  left: 0%;
}

.tab h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #4e4e4e;
}
.sidebar {
  position: fixed;
  width: 18%;
  background: #fdfdfd;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.04);
}

.angolaLogo {
  width: 90px;
  height: 73px;
}

.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0px 50px 0px;
}

.icon {
  width: 17px;
  height: 17px;
}

.icon2 {
  width: 20px;
  height: 17px;
  /* margin-right: 20px; */
}

.logout {
  margin-top: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: none;
  background: transparent;
  padding-left: 20px;
  color: var(--primaryRed);
}

.logout:hover {
  cursor: pointer;
}

.link,
.linkLogout {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 45px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-left: 20px;
  text-decoration: none;
  color: #1d1d1d;
  gap: 5%;
}

.link:hover {
  background-color: #031569;
  color: white !important;
  font-weight: 500;
  transition: cubic-bezier();
}

.link:hover img {
  /* background-color: #031569;
  color: white !important;
  font-weight: 500; */

  -webkit-filter: invert(1) contrast(500%);
}

.link img:hover {
  -webkit-filter: invert(1) contrast(500%);
}

.linkLogout {
  color: var(--primaryRed);
}

.linkSmall {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 36px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding: 0px 10px;
  text-decoration: none;
  color: #1d1d1d;
  margin-left: -20px;
  background-color: none !important;
}

.profile {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile > img {
  margin-right: 20px;
}

.profile h2:nth-child(1) {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1d1d1d;
}

.profile h2:nth-child(2) {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.5;
}

.userSubCategory {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-left: 10px;
  margin-right: -25px;
  width: 90%;
}

.addSubCategory {
  margin-top: 5px;
  margin-bottom: 10px;
  padding-left: 20px;
  margin-right: -55px;
  width: 100%;
}

.rightSide {
  margin-left: 18%;
  padding: 20px 5px 20px 20px;
  height: auto;
  min-height: 100%;
  background: #f7f9ff;
}

@media (max-width: 1280px) {
  .link {
    padding-left: 9px;
  }
}

.infoAndDocuments {
  display: flex;
  gap: 4%;
  width: 100%;
  margin: 10px 0px 0px 10px;
  width: 100%;
}

/* .cuInfo {
  background-color: #ebeffe;
  margin-left: 10px;
  border-radius: 15px;
  padding: 10px;
} */

.accordion {
  min-height: auto;
  height: 400px;
}

.cuInfo,
.docInfo {
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  /* border: #ebeffe 1px solid; */
  margin-left: 10px;
  border-radius: 15px;
  width: 40%;
  /* padding: 10px; */
}
.docImages {
  margin-left: 20px;
}

.heading {
  display: flex;
  gap: 1%;
  border-bottom: 0.9px solid #e7e7e7;
  padding-bottom: 10px;
}

.heading2 {
  display: flex;
  gap: 1%;
  justify-content: space-between;
  padding-bottom: 20px;
}

.heading2 img {
  margin-bottom: 20px;
  width: 32px;
  height: 32px;
}

.headingModal {
  display: flex;
  flex-direction: column;
  gap: 1%;
  padding-bottom: 20px;
}

.long {
  height: 50%;
  display: none;
}

.headingAndBackButton {
  display: flex;
  align-items: center;
  gap: 1%;
  border-bottom: 0.6px solid #e7e7e7;
  padding-bottom: 20px;
  color: #031569;
  width: 100%;
}

.headingAndBackButton img {
  width: 15px;
  height: 25px;
}

.heading2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.heading h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #031569;
}

.row {
  display: flex;
  justify-content: space-between;
}

.infoAndDocuments h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  margin: 10px;

  letter-spacing: 0.02em;

  color: #000000;
}

.row h3:nth-child(1) {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #858585;
  margin: 10px;
}

.row h3:nth-child(2) {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  margin: 10px;
  color: #1e1e1e;
}

.downloadContainers {
  margin-top: 20px;
  display: flex;
  width: 100%;
  gap: 2.5%;
}

.newCustomer {
  background: #ffffff;
  border-radius: 8px;
  padding: 0px 0px 10px 0px;
}

.licence,
.insurance {
  width: 551px;
  height: 279px;
  padding: 20px;
  background: #e6eaff;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
}
.docImages h1 {
  margin: 30px 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: 0.02em;

  color: #000000;
}

.licence h3,
.insurance h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #000000;
}

.licence img {
  width: 226px;
  height: 155.3px;
  align-self: center;
  margin: 20px 0px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.insurance img {
  width: 266.12px;
  height: 155px;
  align-self: center;
  margin: 20px 0px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.dFlex {
  display: flex;
}

.viewImageContainer {
  display: flex;
}

.download {
  display: flex;
  align-items: center;
  gap: 2%;
}

.download h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.03em;

  /* Blue */

  color: #031569;
}

.buttons {
  display: flex;
  margin-left: 20px;
  border-bottom: 1px solid #e3e3e3;
}

.tab {
  margin: 50px 20px 0px 0px;
  font-size: 16px;
}

.normalTab {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #858585;
  padding-bottom: 10px;
}

.selectedTab {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  /* color: #031569; */
  color: #031569;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
  /* padding-left: 20px; */
}

.download img {
  width: 20px;
  height: 20px;
}

.viewDoc {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.viewDoc h3 {
}

.viewDoc img {
  width: 15px;
  height: 15px;
}

.view {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  text-decoration-line: underline;

  /* Blue */

  color: #031569 !important;
}

.previousRideTable {
  margin-top: 10px;
  margin-left: 20px;
}

.rejectUser {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 05px;

  color: #000000;
}

.modalContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.crossIcon {
  width: 30px;
  height: 30px;
  margin-bottom: 40px;
  margin-right: 40px;
}

.modalHeading {
  margin: 20px 0px;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  color: #031569;
}

.createSubscription {
  padding: 50px;
  width: 503px;
  height: 400px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
}

.heading {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 10px;
}

.fRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.marginRight {
  margin-right: 10px;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.heading h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #031569;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.actionButtons {
  width: 100%;
  align-self: flex-end;
}

.carID {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.big {
  font-weight: 600;
  font-size: 26px;
  line-height: 19px;
  color: #000000;
  text-align: start;
}

.carID label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.carID textArea {
  height: 150px;
  padding: 10px;
}

.carID input {
  height: 53px;
}

.carID textArea,
.carID input {
  margin-top: 10px;
  border: transparent;
  background: #f3f2f3;
  border-radius: 8px;
  padding-left: 20px;
}

.actionButtons {
  width: 100%;
  margin-top: 45px;
  display: flex;
  justify-content: flex-end;
  gap: 3%;
}

.center {
  justify-content: center;
}

.about {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #a3a3a3 !important;
}

.heading img {
  height: 20px;
}
